



























































import { sendTemplatedEmail } from '@/common/notificationServices';
import { post, put } from '@/common/rest';
import { isEmailValid } from '@/common/validatorRules';
import EventBus, { EVENTS, TOAST } from '@/eventBus';
import store, { ActionsTypes, GettersTypes } from '@/store';
import { CompletePart, CompleteUploadFlag, FilePart, FileState, FileStatus, FileTracker, FileUpload } from '@/utils/Types/UploadTypes';
import { completeMultipartUpload, createSignedUrl, createUpload } from '@/utils/apiCalls';
import { getFileInfo, getFilePartsInfo, MaximumRemainingTime, MB, sleep, updateFileTrackerInDb, UploadFileInfo } from '@/utils/fileUtilities';
import { FileInfoTypeForSendingEmail } from '@qmu/common/dto/FileTrackerDto';
import { ItemState, SingleUploadSignedUrlPayload, SingleUploadSignedUrlResponse } from '@qmu/common/dto/itemModels';
import { DpaEmailTemplateData, EmailReceipentType, Emails, EmailTemplateType } from '@qmu/common/dto/notificationModels';
import { changeDateTimeFormatToEuropean, getTodaysEuropeanDate } from '@qmu/common/util/formatData';
import { getReadableFileSize, getSecInPretifiedMinute } from '@qmu/common/util/general';
import { sanitizeFilename } from '@qmu/common/util/sanitize';
import { cloneDeep } from 'lodash';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { createJsonFileName } from '../utils/jsonFileUtilities';

@Component
export default class UploadFile extends Vue {
  @Prop({ required: true, type: File }) file!: FileUpload;
  @Prop({ required: true, type: Number }) fileNumber!: number;
  @Prop({ required: true }) jsonFileContent!: string;

  cancel = false;
  partCount = 4;

  async cancelUpload() {
    if (!this.tracker.uploadIdGenerated) {
      // wait for the program to generate upload id
      window.setTimeout(this.cancelUpload, 2000);
      return;
    }
    this.cancel = true;
    this.tracker.speed = 0;
    this.tracker.remainingTime = MaximumRemainingTime;
    this.tracker.abortController.abort();
    this.$set(this.allFileStatus, this.tracker.name, 'cancelled');
    await updateFileTrackerInDb(this.itemId, this.tracker, ItemState.CANCELLED);
    this.changeFileStatus();
    if (this.isUploadComplete()) this.sendEmailToUsers(EmailTemplateType.SUCCESS);
    if (this.isUploadStopped() && this.getFailedFileInfoOfEmail().length) this.sendEmailToUsers(EmailTemplateType.FAILED);
  }

  private changeFileStatus() {
    for (const file of this.files) {
      if (this.allFileStatus[file.name] === 'queued') {
        this.$set(this.allFileStatus, file.name, 'processing');
        break;
      }
    }
  }

  isUploadComplete(): boolean {
    let someThingUploaded = false;
    for (const file of this.files) {
      if (this.allFileStatus[file.name] === 'complete') someThingUploaded = true;
      else if (this.allFileStatus[file.name] === 'queued' || this.allFileStatus[file.name] === 'processing') return false;
    }
    return someThingUploaded;
  }

  isUploadStopped(): boolean {
    for (const file of this.files) {
      if (this.allFileStatus[file.name] === 'queued' || this.allFileStatus[file.name] === 'processing') return false;
    }
    return true;
  }

  get progressColor() {
    if (this.tracker.error) return 'progressError';
    else if (!this.metadataFieldStatus && this.tracker.percentage === 100) return 'progressWarning';
    else if (this.tracker.percentage < 100) return 'progressPrimary';
    else return 'progressSuccess';
  }

  get userEmail() {
    return store.getters[GettersTypes.GET_USER_EMAIL];
  }

  get startFlag() {
    return store.getters[GettersTypes.GET_UPLOAD_START];
  }

  get xmpEmail() {
    return store.getters[GettersTypes.GET_XMP_EMAIL];
  }

  set xmpEmail(value: string) {
    store.dispatch(ActionsTypes.SET_XMP_EMAIL, value);
  }

  get files(): FileUpload[] {
    return store.getters[GettersTypes.GET_FILES];
  }

  get allFileStatus(): FileStatus {
    return store.getters[GettersTypes.GET_ALL_FILE_STATUS];
  }

  get completeUploadFlags() {
    return store.getters[GettersTypes.GET_COMPLETE_UPLOAD_FLAGS] as Array<CompleteUploadFlag>;
  }

  get fileCompleteStatus() {
    if (!this.metadataFieldStatus && this.tracker.percentage === 100) return false;
    for (const flag of this.completeUploadFlags) if (flag.key === this.file.name) return flag.value;
    return false;
  }

  updateCompleteUploadFlag(value: boolean) {
    store.dispatch(ActionsTypes.UPDATE_COMPLETE_UPLOAD_FLAGS, { key: this.file.name, value: value });
  }

  getCompletedFileInfoOfEmail(): FileInfoTypeForSendingEmail[] {
    return cloneDeep<FileInfoTypeForSendingEmail[]>(store.getters[GettersTypes.GET_COMPLETED_FILE_INFO_TO_EMAIL]);
  }

  setCompletedFileInfoForEmail(fileName: string, itemId: string) {
    store.dispatch(ActionsTypes.SET_COMPLETED_FILE_INFO_TO_EMAIL, { fileName, itemId });
  }

  clearCompletedFileInfoForEmail(files: FileInfoTypeForSendingEmail[]) {
    store.dispatch(ActionsTypes.CLEAR_COMPLETED_FILE_INFO_TO_EMAIL, files);
  }

  getFailedFileInfoOfEmail(): FileInfoTypeForSendingEmail[] {
    return cloneDeep<FileInfoTypeForSendingEmail[]>(store.getters[GettersTypes.GET_FAILED_FILE_INFO_TO_EMAIL]);
  }

  setFailedFileInfoForEmail(fileName: string, itemId: string) {
    store.dispatch(ActionsTypes.SET_FAILED_FILE_INFO_TO_EMAIL, { fileName, itemId });
  }

  clearFailedFileInfoForEmail(files: FileInfoTypeForSendingEmail[]) {
    store.dispatch(ActionsTypes.CLEAR_FAILED_FILE_INFO_TO_EMAIL, files);
  }

  get fileStatus(): FileState {
    return this.allFileStatus[this.file.name];
  }

  set fileStatus(value: FileState) {
    this.allFileStatus[this.file.name] = value;
  }

  getTruncateMiddleOfText(title: string) {
    title = sanitizeFilename(title);
    const titleLength = title.length;
    if (titleLength > 20) {
      return title.substr(0, 10) + '...' + title.substr(titleLength - 8, titleLength);
    }
    return title;
  }

  filenameSanitizer(fileName: string) {
    return sanitizeFilename(fileName)
  }

  get tracker(): FileTracker {
    if (store.getters[GettersTypes.GET_FILE_TRACKERS]) {
      const trackers = store.getters[GettersTypes.GET_FILE_TRACKERS];
      for (const tracker of trackers) {
        if (tracker.name === this.file.name) return tracker;
      }
    }

    const track: FileTracker = {
      lastSecTotalUploaded: 0,
      lastSecTimestamp: Date.now(),
      remainingTime: 0,
      fileIndex: this.fileNumber,
      name: this.file.name,
      speed: 0,
      uploaded: 0,
      realPercentage: 0,
      percentage: 0,
      error: false,
      fileProcessingStarted: false,
      uploadIdGenerated: false,
      abortController: new AbortController(),
    };
    store.dispatch(ActionsTypes.ADD_FILE_TRACKER, track);
    return track;
  }

  itemId: string = '';
  itemFileId: string = '';
  originName: string = '';
  jsonId: string = '';

  partList: FilePart[] = [];
  completedPartList: CompletePart[] = [];

  jsonPartList: FilePart[] = [];
  jsonCompletedPartList: CompletePart[] = [];
  errorMessage = '';

  get metadataFieldStatus() {
    return store.getters[GettersTypes.GET_METADATA_FIELD_STATUS];
  }

  set metadataFieldStatus(value: boolean) {
    store.dispatch(ActionsTypes.UPDATE_METADATA_FIELD_STATUS, value);
  }

  get uploadedInMb() {
    return `${(this.tracker.uploaded / 1024 / 1024).toFixed(2)} MB`;
  }

  get fileSizeInMb() {
    return `${(this.file.size / 1024 / 1024).toFixed(2)} MB`;
  }

  get fileData() {
    return this.file;
  }

  get fileIndex() {
    return this.tracker.fileIndex;
  }

  get currentStatus() {
    if (this.fileStatus === 'processing' && !this.tracker.fileProcessingStarted && this.startFlag) {
      this.processFile(this.file);
    }
    return this.fileStatus;
  }

  get uploadedStatus() {
    return ` Uploaded - ${this.uploadedInMb} MB / ${(this.file.size / 1024 / 1024).toFixed(2)} MB`;
  }

  get remainingTime() {
    return this.tracker.remainingTime >= 60 ? `${getSecInPretifiedMinute(this.tracker.remainingTime)} min` : `${this.tracker.remainingTime.toFixed(2)} sec`;
  }

  get speedNotification() {
    if (!this.tracker.speed) return '0 Kbps';
    return this.tracker.speed >= 1000 ? `${getReadableFileSize(this.tracker.speed)}` : `${this.tracker.speed.toFixed(2)} Kbps`;
  }

  async processFile(file: FileUpload) {
    this.partList = getFilePartsInfo(file);
    this.tracker.fileProcessingStarted = true;

    // can't upload empty files
    if (this.partList.length <= 0) {
      this.errorMessage = 'Empty files are not allowed to upload!';
      this.tracker.error = true;
      return;
    }
    while (true) {
      try {
        if (!this.itemId) {
          const fileInfo: UploadFileInfo = await getFileInfo(file);
          this.itemId = fileInfo.id;
          this.itemFileId = fileInfo.fileId;
          this.originName = fileInfo.title;
        }

        if (!this.tracker.uploadIdGenerated) {
          await createUpload(this.itemId);
          this.tracker.uploadIdGenerated = true;
        }

        await this.handleUpload(file);
        break;
      } catch (err) {
        this.tracker.remainingTime = MaximumRemainingTime;
        this.tracker.speed = 0;
        if (this.cancel) break;
        if (err.response && err.response?.status === 400) {
          this.errorMessage = 'File type is not supported. Please refresh your browser';
          this.tracker.error = true;
          EventBus.$emit(EVENTS.SHOW_TOAST, this.errorMessage, TOAST.ERROR);
          break;
        }
        if (err.response && err.response?.status >= 500) {
          if (err.response?.status == 500) this.errorMessage = 'Server could not handle request. Try again later';
          else if (err.response?.status == 503) this.errorMessage = err.response?.data?.error?.message || 'Something went wrong in min.io';
          else this.errorMessage = err.response?.data?.error?.message || 'Something Went Wrong Please try again later';
          this.tracker.error = true;
          EventBus.$emit(EVENTS.SHOW_TOAST, this.errorMessage, TOAST.ERROR);
          this.setFailedFileInfoForEmail(this.file.name, this.itemId);
          if (this.isUploadStopped()) this.sendEmailToUsers(EmailTemplateType.FAILED);
          break;
        }
        // retrying to create itemId , uploadId
      }
    }
  }

  async handleFile(file: FileUpload) {
    this.partCount = file.size < 1000 * MB ? 2 : 4;
    while (this.partList.length) {
      const fourParts = this.partList.slice(0, this.partCount);
      await Promise.all(fourParts.map(async (part: FilePart) => this.createSignedUrlAndUploadPart(part, file)));
    }
  }

  async uploadParts(data: FilePart[], file: FileUpload) {
    const batch = [];
    for (const item of data) {
      if (item.signedUrl) batch.push(this.uploadPartAndUpdatePartInfoList(item.PartNumber, item.signedUrl, file.slice(item.start, item.end)));
    }
    await Promise.all(batch);
  }

  async createSignedUrlWithRetry(itemId: string, partNumber: number, waitBeforeExecutionInMillis = 500) {
    let tryNumber = 1;
    while (tryNumber) {
      try {
        const {
          data: {
            data: { url },
          },
        } = await createSignedUrl(itemId, partNumber);
        this.errorMessage = '';
        this.tracker.error = false;
        return url;
      } catch (err) {
        this.tracker.remainingTime = MaximumRemainingTime;
        this.tracker.speed = 0;
        if (this.cancel) break;
        // has problems when token is invalid // doesn't return anything // need to fix this
        this.errorMessage = `Retrying  (${tryNumber})`;
        if (tryNumber > 10) EventBus.$emit(EVENTS.SHOW_TOAST, 'Something is wrong. Upload May not work right now', TOAST.ERROR);
        waitBeforeExecutionInMillis = tryNumber > 220 ? 60 * 1000 : tryNumber > 110 ? 30 * 1000 : waitBeforeExecutionInMillis * 2;
        await sleep(waitBeforeExecutionInMillis);
        tryNumber++;
      }
    }
  }

  async tryCompleteMultipart() {
    this.updateCompleteUploadFlag(true);
    if (!this.metadataFieldStatus) {
      window.setTimeout(this.tryCompleteMultipart, 5000);
    } else {
      this.completedPartList = this.completedPartList.sort((a, b) => {
        return a.PartNumber > b.PartNumber ? 1 : -1;
      });
      await this.uploadJsonFile(createJsonFileName(this.itemFileId, this.originName), this.jsonFileContent);
      await completeMultipartUpload(this.itemId, this.completedPartList);
      await this.completed();
      this.updateCompleteUploadFlag(false);
    }
  }

  async handleUpload(file: FileUpload) {
    await this.handleFile(file);
    this.changeFileStatus();
    while (true) {
      try {
        await this.tryCompleteMultipart();
        this.tracker.percentage = this.tracker.realPercentage;
        this.tracker.percentage = this.tracker.percentage;
        this.errorMessage = '';
        this.tracker.error = false;
        break;
      } catch (err) {
        this.tracker.remainingTime = MaximumRemainingTime;
        this.tracker.speed = 0;
        if (this.cancel) break;
        if (err.response && err.response?.status >= 500) {
          this.errorMessage = 'Somethine went wrong, Please try again later';
          this.tracker.error = true;
          break;
        }
        // retrying to complete part
      }
    }
  }

  async createSignedUrlAndUploadPart({ start, end, PartNumber }: FilePart, file: FileUpload) {
    const currentChunk = file.slice(start, end);
    const signedUrl = await this.createSignedUrlWithRetry(this.itemId, PartNumber);
    return await this.uploadPartAndUpdatePartInfoList(PartNumber, signedUrl!, currentChunk);
  }

  async uploadPartAndUpdatePartInfoList(partNumber: number, signedUrl: string, chunk: Blob, tryNumber = 1, waitBeforeExecutionInMillis = 500) {
    while (tryNumber) {
      try {
        const ETag = await this.uploadPart(signedUrl, chunk, this.tracker.abortController.signal);

        this.completedPartList.push({ ETag, PartNumber: partNumber });
        this.partList = this.partList.filter(element => element.PartNumber !== partNumber);
        this.errorMessage = '';
        this.tracker.error = false;
        return;
      } catch (err) {
        this.tracker.remainingTime = MaximumRemainingTime;
        this.tracker.speed = 0;
        if (this.cancel) throw err;
        if (err.response?.status >= 400 && err.response?.status < 500) {
          // presigned url usually expires within 15 minutes. so, recreating the url
          signedUrl = await this.createSignedUrlWithRetry(this.itemId, partNumber);
          waitBeforeExecutionInMillis = tryNumber > 220 ? 60 * 1000 : tryNumber > 110 ? 30 * 1000 : waitBeforeExecutionInMillis * 2;
          tryNumber++;
          await sleep(waitBeforeExecutionInMillis);
        } else if (err.response?.status >= 500) {
          this.errorMessage = err.response?.data || 'Something went wrong, Please try again later';
          this.tracker.error = true;

          this.tracker.abortController.abort();
          this.tracker.abortController = new AbortController();
          throw err;
        } else {
          // Sleep 5 sec on TCP connection reset problem and others
          tryNumber++;
          await sleep(5000);
        }
      }
    }
  }

  async uploadPart(signedUrl: string, chunk: Blob, signal: AbortSignal) {
    let uploadedByteYet = 0;
    try {
      const response = await put(signedUrl, {
        data: chunk,
        signal: signal,
        onUploadProgress: ({ loaded: currentUploadedByte, timeStamp: timePassedYet }: { loaded: number; timeStamp: number }) => {
          const uploadedNow = currentUploadedByte - uploadedByteYet;
          uploadedByteYet = currentUploadedByte;
          this.tracker.uploaded = this.tracker.uploaded + uploadedNow;

          const currentTimestamp = Date.now();
          if (!this.tracker.lastSecTimestamp) this.tracker.lastSecTimestamp = currentTimestamp;
          this.tracker.realPercentage = (this.tracker.uploaded * 100) / this.file.size;
          if (!(this.tracker.lastSecTimestamp <= currentTimestamp - 1000)) return;
          this.tracker.speed = Math.abs((this.tracker.uploaded - (this.tracker.lastSecTotalUploaded ?? 0)) / (currentTimestamp - this.tracker.lastSecTimestamp));
          this.tracker.remainingTime = (this.file.size - this.tracker.uploaded) / 1024 / this.tracker.speed;
          this.tracker.lastSecTimestamp = Date.now();
          this.tracker.lastSecTotalUploaded = this.tracker.uploaded;
        },
      });
      await updateFileTrackerInDb(this.itemId, this.tracker, ItemState.PROGRESSING, JSON.parse(this.jsonFileContent));

      if (this.tracker.realPercentage < 100) {
        this.tracker.percentage = this.tracker.realPercentage; // will show 100% when the completeUploadParts request is done, Hence checking if less then 100
        this.tracker.percentage = this.tracker.percentage;
      }
      return response.headers.etag;
    } catch (err) {
      this.tracker.uploaded -= uploadedByteYet;
      if (this.tracker.lastSecTotalUploaded) this.tracker.lastSecTotalUploaded -= uploadedByteYet;
      this.tracker.percentage = (this.tracker.uploaded * 100) / this.file.size;
      this.tracker.realPercentage = this.tracker.percentage;
      throw err;
    }
  }

  async completed() {
    this.$set(this.allFileStatus, this.file.name, 'complete');
    this.setCompletedFileInfoForEmail(this.file.name, this.itemId);
    if (this.isUploadComplete()) this.sendEmailToUsers(EmailTemplateType.SUCCESS);
  }

  get metadataFields() {
    return store.getters[GettersTypes.GET_METADATA_FIELD_VALUES];
  }

  getMetadataTitle() {
    return this.metadataFields['title'];
  }

  getGermanDateTime() {
    return changeDateTimeFormatToEuropean(new Date().toISOString());
  }

  getPretifiedZeusId() {
    const zeusEventId = this.metadataFields['eventId'];
    return zeusEventId ? `Zeus Event-ID: ${zeusEventId}` : '';
  }

  getPretifiedZeusOriginatorId() {
    const zeusOriginatorId = this.metadataFields['originatorId'];
    return zeusOriginatorId ? `Zeus Dispo-ID: ${zeusOriginatorId}` : '';
  }

  getUploadedDate() {
    return getTodaysEuropeanDate();
  }


  async sendEmailToUsers(emailType: EmailTemplateType) {
    const emails: Emails = {userEmail: this.userEmail as string, xmpEmail: this.xmpEmail}
    await Promise.all([this.sendEmailToUser(emailType, emails)]);
  }

  hasEmail(emails:Emails) {
    if(typeof emails !== 'object') return false;
    return Object.values(emails).some(email => typeof email === 'string' ? isEmailValid(email) : false);
  }

  sanitizeFilenames(filesInfo: FileInfoTypeForSendingEmail[]) {
    return filesInfo.map(fileInfo => {
      return {
        ...fileInfo,
        fileName: sanitizeFilename(fileInfo.fileName)
      };
    })
  }

  async sendEmailToUser(emailType: EmailTemplateType, emails: Emails, fromXmp: boolean = false) {
    if (!this.hasEmail(emails) || !this.getMetadataTitle()) return;
    const files = emailType === EmailTemplateType.SUCCESS ? this.getCompletedFileInfoOfEmail() : this.getFailedFileInfoOfEmail();
    const templateData: DpaEmailTemplateData = {
      user: '',
      metadataTitle: this.getMetadataTitle(),
      germanDateTime: this.getGermanDateTime(),
      zeusIdPretified: this.getPretifiedZeusId(),
      zeusOriginatorIdPretified: this.getPretifiedZeusOriginatorId(),
      uploadedFileNames: this.sanitizeFilenames(files),
      uploadDate: this.getUploadedDate(),
    };
    const isEmailSendToAll = store.getters[GettersTypes.GET_SUBSCRIPTION_INFO] && emails.userEmail;
    const targetRecepientType =  isEmailSendToAll ? EmailReceipentType.ALL : EmailReceipentType.ADMIN;
    try {
      await sendTemplatedEmail(targetRecepientType, emailType, templateData, emails);
      if (targetRecepientType !== EmailReceipentType.ADMIN) EventBus.$emit(EVENTS.SHOW_TOAST, 'Email sent', TOAST.SUCCESS);
      if (emailType === EmailTemplateType.SUCCESS) this.clearCompletedFileInfoForEmail(files);
      else if (emailType === EmailTemplateType.FAILED) this.clearFailedFileInfoForEmail(files);
    } catch (error) {
      if (error.response?.status === 409) {
        EventBus.$emit(EVENTS.SHOW_TOAST, error.response.data?.error?.message ?? 'Something wrong', TOAST.INFO);
        return;
      }
      if (targetRecepientType !== EmailReceipentType.ADMIN) EventBus.$emit(EVENTS.SHOW_TOAST, 'Email sending failed', TOAST.ERROR);
    }
  }

  private async uploadJsonFile(fileName: string, data: string) {
    const parts = [data];

    const file = new File(parts, fileName, {
      lastModified: new Date().getUTCDate(),
      type: 'text/plain',
    });
    await this.uploadJsonToS3(file as FileUpload);
    await this.processJsonFile(file as FileUpload);
  }

  async metaDataSingleUpload(signedUrl: string, file: FileUpload, waitBeforeExecutionInMillis = 500) {
    let tryNumber = 1;
    while (tryNumber) {
      try {
        await put(signedUrl, { data: file });
        break;
      } catch (error) {
        if (error.response && error.response.status >= 500) {
          throw error;
        }
        waitBeforeExecutionInMillis = tryNumber > 220 ? 60 * 1000 : tryNumber > 110 ? 30 * 1000 : waitBeforeExecutionInMillis * 2;
        tryNumber++;
        await sleep(waitBeforeExecutionInMillis);
      }
    }
  }

  async uploadJsonToS3(file: FileUpload) {
    const payload: SingleUploadSignedUrlPayload = {
      fileName: file.name,
    };
    while (true) {
      try {
        const resp = await post(store.getters[GettersTypes.GET_METADATA_ON_S3_LINK], { data: payload });
        const signedUrlResp: SingleUploadSignedUrlResponse = resp.data.data;
        await this.metaDataSingleUpload(signedUrlResp.url, file);
        break;
      } catch (error) {
        if (error.response && error.response.status >= 500) {
          if (error.response.status == 500) this.errorMessage = 'Failed to keep copy of metadata';
          else this.errorMessage = error.response.data.error.message || 'Failed to keep copy of metadata';
          break;
        }
      }
    }
  }

  async processJsonFile(jsonFile: FileUpload) {
    this.jsonPartList = getFilePartsInfo(jsonFile);

    while (true) {
      try {
        if (!this.jsonId) {
          const fileInfo: UploadFileInfo = await getFileInfo(jsonFile);
          this.jsonId = fileInfo.id;
        }
        await createUpload(this.jsonId);

        await this.handleJsonUpload(jsonFile);
        break;
      } catch (err) {
        if (err.response?.status >= 500) {
          if (err.response?.status == 500) this.errorMessage = 'Something Went Wrong Please try again later';
          else this.errorMessage = err.response?.data?.error?.message || 'Something Went Wrong Please try again later';
          break;
        }
        // retrying to create itemId , uploadId
      }
    }
  }

  async createSignedUrlAndUploadPartJson({ start, end, PartNumber }: FilePart, file: FileUpload) {
    const currentChunk = file.slice(start, end);
    const signedUrl = await this.createSignedUrlWithRetryJson(this.jsonId, PartNumber);
    return await this.uploadPartAndUpdatePartInfoListJson(PartNumber, signedUrl!, currentChunk);
  }

  async createSignedUrlWithRetryJson(itemId: string, partNumber: number, waitBeforeExecutionInMillis = 500) {
    let tryNumber = 1;
    while (tryNumber) {
      try {
        const {
          data: {
            data: { url },
          },
        } = await createSignedUrl(itemId, partNumber);
        this.errorMessage = '';
        return url;
      } catch (err) {
        // has problems when token is invalid // doesn't return anything // need to fix this
        this.errorMessage = `Retrying  (${tryNumber})`;
        waitBeforeExecutionInMillis = tryNumber > 220 ? 60 * 1000 : tryNumber > 110 ? 30 * 1000 : waitBeforeExecutionInMillis * 2;
        tryNumber++;
        await sleep(waitBeforeExecutionInMillis);
      }
    }
  }

  async uploadPartAndUpdatePartInfoListJson(partNumber: number, signedUrl: string, chunk: Blob, tryNumber = 1, waitBeforeExecutionInMillis = 500) {
    while (tryNumber) {
      try {
        const ETag = await this.uploadPartJson(signedUrl, chunk, this.tracker.abortController.signal);

        this.jsonCompletedPartList.push({ ETag, PartNumber: partNumber });
        this.jsonPartList = this.jsonPartList.filter(element => element.PartNumber !== partNumber);
        this.errorMessage = '';
        return;
      } catch (err) {
        if (err.response?.status === 403) {
          // presigned url usually expires within 15 minutes. so, recreating the url
          signedUrl = await this.createSignedUrlWithRetryJson(this.itemId, partNumber);
        }

        if (err.response?.status >= 500) {
          this.errorMessage = err.response?.data || 'Something went wrong, Please try again later';

          this.tracker.abortController.abort();
          this.tracker.abortController = new AbortController();
          throw err;
        }
        waitBeforeExecutionInMillis = waitBeforeExecutionInMillis > 5000 ? 5000 : waitBeforeExecutionInMillis * 2;
        tryNumber++;
        await sleep(waitBeforeExecutionInMillis);
      }
    }
  }

  async handleJsonFile(file: FileUpload) {
    while (this.jsonPartList.length) {
      const fourParts = this.jsonPartList.slice(0, 4);
      await Promise.all(fourParts.map(async (part: FilePart) => this.createSignedUrlAndUploadPartJson(part, file)));
    }
  }

  async handleJsonUpload(jsonFile: FileUpload) {
    await this.handleJsonFile(jsonFile);
    this.jsonCompletedPartList = this.jsonCompletedPartList.sort((a, b) => {
      return a.PartNumber > b.PartNumber ? 1 : -1;
    });

    while (true) {
      try {
        await completeMultipartUpload(this.jsonId, this.jsonCompletedPartList);
        break;
      } catch (err) {
        if (err.response?.status >= 500) {
          this.errorMessage = 'Somethine went wrong, Please try again later';
          break;
        }
        // retrying to complete part
      }
    }
  }

  async uploadPartJson(signedUrl: string, chunk: Blob, signal: AbortSignal) {
    try {
      const response = await put(signedUrl, {
        data: chunk,
        signal: signal,
      });
      return response.headers.etag;
    } catch (err) {
      throw err;
    }
  }
}
